
import { Options, Vue } from "vue-class-component";

interface HomeCardContent {
  title: string;
  content: string;
}

export default class Home extends Vue {
  contents: Array<HomeCardContent> = [
    {
      title: "sdfsf",
      content: "fadfadasew",
    },
  ];
}

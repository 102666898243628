<template>
  <el-container>
    <!-- <el-header id="header">
      <NavBar />
    </el-header> -->
    <el-main>
      <router-view />
    </el-main>
    <el-footer class="footer">
      <el-container>
        <div class="footer-container">
          <el-row>
            <el-col :justify="center">
              <div>
                <span class="small"> Copyright © 2022 Bunny Connect LLC </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-container>
    </el-footer>
  </el-container>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: {
    // NavBar,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

#header {
  padding: 0;
}

.el-footer {
  padding: 0 !important;
}

.footer-container {
  background-color: #d5ecc2;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: small;
  /* min-height: 300px; */
}
</style>

<style scoped>
.footer-list ul {
  list-style: none;
}

.footer-list .footer-list-title {
  font-size: 14pt;
  font-weight: bold;
}
</style>
